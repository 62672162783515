$(document).ready(function () {

	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.header__top').addClass('active');
		} else {
			$('.header__top').removeClass('active');
		}
	});

	$('.header__controls .search').on('click', function () {
		$(this).closest('.header__controls').find('.search__form--drop').toggleClass('active');
		if ($(this).find('.fas').hasClass('fa-search')) {
			$(this).find('.fas').removeClass('fa-search');
			$(this).find('.fas').addClass('fa-times');
		} else {
			$(this).find('.fas').addClass('fa-search');
			$(this).find('.fas').removeClass('fa-times');
		}
	});

	var swiper1 = new Swiper('.swiper-container1', {
		slidesPerView: 1,
		spaceBetween: 30,
		loop: true,
		loopFillGroupWithBlank: true,
		autoplay: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
	});

	var swiper = new Swiper('.swiper-container', {
		slidesPerView: 4,
		spaceBetween: 30,
		loop: false,
		navigation: {
			nextEl: '.docNext',
			prevEl: '.docPrev',
		},
		pagination: {
			el: '.swiper-pagination1',
			clickable: true
		},
		breakpoints: {
			1200: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 2,
			},
			480: {
				slidesPerView: 1,
				spaceBetween: 20,
			},
		}
	});

	function sliderInit1() {
		var swiper2 = new Swiper('.swiper-container2', {
			slidesPerView: 4,
			spaceBetween: 0,
			navigation: {
				nextEl: '.next',
				prevEl: '.prev',
			},
			breakpoints: {
				1200: {
					slidesPerView: 3,
				},
				992: {
					slidesPerView: 2,
				},
				568: {
					slidesPerView: 1,
				},
			}
		});
	};
	sliderInit1();

	function sliderInit() {
		var swiper3 = new Swiper('.swiper-container3', {
			slidesPerView: 3,
			spaceBetween: 30,
			loop: true,
			navigation: {
				nextEl: '.docNext',
				prevEl: '.docPrev',
			},
			breakpoints: {
				1200: {
					slidesPerView: 3,
				},
				992: {
					slidesPerView: 2,
				},
				568: {
					slidesPerView: 1,
				},
			}
		});
	}
	$('.tabs__item').on('click', function () {
		$('.tabs__item').removeClass('active');
		$(this).addClass('active');
	});

	$('.burger').on('click', function () {
		$(this).toggleClass('active');
		if ($(window).width() > 992) {
			$(this).closest('.header__row').find('.menu-desktop').fadeToggle();
		} else {
			$('.menu-mobile').toggleClass('active');
			if ($('.overlay').is(":visible")) {
				$('.overlay').fadeOut();
			} else {
				$('.overlay').fadeIn();
			}
		}

	});

	$('.overlay').on('click', function () {
		$('.overlay,.modal-overflow').fadeOut();
		$('.menu-mobile,.burger').removeClass('active');
	});

	$('.menu-mobile .menu__item--sub').on('click', function () {
		$(this).toggleClass('active');
	});

	$('select').styler();

	$('.tabs__top').on('click', function () {
		$(this).toggleClass('active');
		$(this).closest('.tabs__block').find('.tabs__inside').slideToggle();
	});

	$('.tabs--doctor li').each(function (ind, el) {
		$(this).attr('data-tab', ind);
	});

	$('.tabs__mobile--doctor .jq-selectbox li').each(function (ind, el) {
		$(this).attr('data-tab', ind);
	});

	$('.doctor__tab').each(function (ind, el) {
		$(this).attr('data-tab', ind);
	});

	$('.tabs--doctor li,.tabs__mobile--doctor .jq-selectbox li').on('click', function () {
		var dataTab = $(this).data('tab');
		$('.doctor__tab').hide();
		$('.doctor__tab[data-tab=' + dataTab + ']').show();
		sliderInit();
	});

	$('.modal__close').on('click', function () {
		$('.modal-overflow,.overlay').fadeOut();
	});

	$('.feedback__create, .feedback__btn').on('click', function (e) {
		e.preventDefault();
		$('.modal-overflow--feedback,.overlay').fadeIn();
	});

	$('.call').on('click', function (e) {
		e.preventDefault();
		$('.overlay,.modal-overflow--call').fadeIn();
	});

	$('.askQuestion').on('click', function (e) {
		e.preventDefault();
		$('.overlay,.modal-overflow--ask').fadeIn();
	});

	$('.requestForm').on('click', function (e) {
		e.preventDefault();
		$('.overlay,.modal-overflow--request').fadeIn();
	});

	$('.modal .newsletter__agree input').on('change', function () {
		if ($(this).hasClass('newsletter__field--clinic')) {
			$('.jq-selectbox').addClass('disabled');
		} else {
			$('.jq-selectbox').removeClass('disabled');
		}
	});

	if ($(window).width() > 992) {
		$(document).mouseup(function (e) {
			var div = $(".menu-desktop,.modal-overflow,.burger"); // тут указываем ID элемента
			if (!div.is(e.target) // если клик был не по нашему блоку
				&&
				div.has(e.target).length === 0) { // и не по его дочерним элементам

				$('.burger,.menu-mobile').removeClass('active');
				$('.overlay,.menu-desktop,.modal-overflow').fadeOut();
			}
		})
	}

	$('.modal-overflow').mouseup(function (e) {
		var div2 = $(".modal"); // тут указываем ID элемента
		if (!div2.is(e.target) // если клик был не по нашему блоку
			&&
			div2.has(e.target).length === 0) { // и не по его дочерним элементам
			$('.overlay,.modal-overflow').fadeOut();
		}
	})


	$(document).mouseup(function (e) { // событие клика по веб-документу
		var div1 = $(".search__form--drop,.search"); // тут указываем ID элемента
		if (!div1.is(e.target) // если клик был не по нашему блоку
			&&
			div1.has(e.target).length === 0) { // и не по его дочерним элементам
			div1.removeClass('active'); // скрываем его
			$('.header__controls .search').find('.fas').addClass('fa-search');
			$('.header__controls .search').find('.fas').removeClass('fa-times');
		}
	});


	$('.ways .tabs li').each(function (ind, el) {
		$(this).attr('data-tab', ind);
	});

	$('.ways .tabs__mobile .jq-selectbox li').each(function (ind, el) {
		$(this).attr('data-tab', ind);
	});

	$('.ways .tabs__content').not(':first-of-type').hide();

	$('.ways .tabs__content').each(function (ind, el) {
		$(this).attr('data-tab', ind);
	});

	$('.ways .tabs li,.ways .tabs__mobile .jq-selectbox li').on('click', function () {
		var dataTab = $(this).data('tab');
		$('.ways .tabs__content').hide();
		$('.ways .tabs__content[data-tab=' + dataTab + ']').show();
		sliderInit1();
	});

	
	$(".tabs__letter li").not('.mark').on("click", function () { //2
		$(".tabs__letter li").removeClass('active');
		$(this).addClass('active');
		var value = $(this).text().toLowerCase(); //3
		$(".tabs__content .tabs__group__link").filter(function () { //4
			$(this).toggle($(this).text().substr(0, 1).toLowerCase().indexOf(value) > -1) //5
		});
	});

	$('.mark').on('click', function () {
		$(".tabs__letter li").removeClass('active');
		$(".tabs__content .tabs__group__link").show();
	});


	/*
	$('.tabs__letter li').not('.mark').on('click', function (evt) {
		evt.preventDefault();

		var $navItem = $(this);
		var $letters = $('.tabs__group__item');

		$letters.show();

		if ($navItem.hasClass('active')) {
			$navItem.removeClass('active');
		} else {
			$('.tabs__letter li').removeClass('active');
			$navItem.addClass('active');

			$.each($letters, function (key, letter) {
				var $letter = $(letter);
				var	$letterName = $letter.find('.tabs__group__link');
				var	$nameArr = $letterName.text().split(' ');

				// console.log($nameArr[0].split('')[0].toLowerCase());

				if ($nameArr[0].split('')[0].toLowerCase() !== $navItem.text().toLowerCase()) {
					$letter.hide();
				}
			});
		}
	});
	*/

});